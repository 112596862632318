import { ITheme } from '@papa/components';
export const theme: ICustomTheme = {
    primary: {
        main: '#0071BA',
        light: '#0085A9',
        dark: '#0060A0',
    },
    success: {
        light: '#B2D5B6',
        main: '#2A7B3B',
        dark: '#1F4426',
    },
    secondary: {
        light: '#003E6B',
        main: '#002D50',
        dark: '#001829',
    },
    accent: {
        light: '#00B0E0',
        main: '#009998',
        dark: '#00708F',
    },
    neutral: {
        faint: '#fbfbfb',
        extraLight: '#eee',
        light: '#dbdbdb',
        main: '#6E6E6E',
        dark: '#303030',
        darkest: 'rgba(0, 0, 0, 0.8)',
    },
    error: {
        light: '#E9A917',
        main: '#C04621',
        dark: '#AF4322',
        darkest: '#6F3638',
    },
    disabled: {
        light: '#dbdbdb',
        main: '#757575',
        dark: '#303030',
    },
    font: {
        main: "'Sen', sans-serif",
        display: "'Montserrat', sans-serif",
        italic: "'Roboto', sans-serif",
        bold: "'Roboto-Bold', sans-serif",
    },
    layout: {
        tablet: '900px',
        mobile: '675px',
    },
    boxShadow: {
        main: '0 3px 10px rgb(0 0 0 / 0.2)',
        heavy: '0 3px 10px rgb(0 0 0 / 0.4)',
    },
    borderRadius: '.2rem',
    white: 'white',
};
/* 
    If an app has styling choices that do not fit in with the provided theme, you can 
    customize your theme type. In the example below, we added different shades of pink 
    (salmon, regular, rose) as well as a light box shadow. Using a theme interface and 
    theme variable makes it easier to update an app in the case of partner rebranding.
*/
export interface ICustomTheme extends ITheme {
    error: {
        light: string;
        main: string;
        dark: string;
        darkest?: string; // example of adding custom choices
    };
    boxShadow: {
        light?: string; // example of adding custom choices
        main: string;
        heavy: string;
    };
    pink?: {
        // example of adding custom choices
        salmon: string;
        regular: string;
        rose: string;
    };
}
